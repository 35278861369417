export const relocateFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Relocate floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const relocateFloorArea = {
  order: 34,
  name: "Relocate floor areas",
  keyword: "move",
  subcategory: "Properties",
  markdown: `# Relocate floor areas

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  It may become necessary to move a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} to a different {% inlineRouterLink articleId="add-a-land-covering" %}building{% /inlineRouterLink %} and/or {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %}. Read on to learn how to do this in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** Relocating a floor area does not change its {% inlineRouterLink %}area measures{% /inlineRouterLink %}, so be sure to double-check that the size is still accurate after you complete the relocation.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} that is attached to one or more {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} and/or one or more {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="relocateFloorArea" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} and/or one or more {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %} to move the floor area to.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The floor area moves onto the selected level(s).
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$relocateFloorAreaVisuals /%}
  `,
};
